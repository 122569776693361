import {
  BreadcrumbGroup,
  Button,
  Header,
  Link,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NoVerifyReasonType } from "../../core/constants";
import getBypasses from "../../helpers/getBypasses";
import { FlashbarPublisher } from "../FlashbarPublisher";
import { AllBypassesTable } from "./AllBypassesTable";
import { isAdmin } from "../../helpers/isAdmin";
import { DismissBypassModal } from "./DismissBypassModal";
import { User } from "../../core/user";
import { isBypassesPhase2On } from "../../helpers/isBypassesPhase2On";

export const AllBypasses = () => {
  const [bypasses, setBypasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDismissBypassModalVisible, setIsDismissBypassModalVisible] =
    useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();

  const fetchBypasses = async () => {
    try {
      setLoading(true);
      const result = await getBypasses();
      setBypasses(result);

      if (
        result.some(
          (bypass) =>
            bypass.noVerifyReasonType === NoVerifyReasonType.Unintentional &&
            bypass.username === new User().email
        )
      ) {
        FlashbarPublisher.pushItem({
          header:
            "Having unknown bypasses is extremely risky as your commits may contain sensitive information like secret keys or access IDs.",
          type: "warning",
          content: (
            <div>
              If the bypass was due to an unknown reason, visit the{" "}
              <Link
                external
                href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/#29"
                variant="primary"
              >
                troubleshooting guide
              </Link>{" "}
              to follow steps to identify the issue and prevent this from
              happening in the future. If you cannot resolve the issue and still
              triggers bypass alert next month, it will be flagged as a
              potential compliance issue and escalate to your manger.
            </div>
          ),
          id: "unkown-bypass-flashbar",
        });
      }

      if (isAdmin()) {
        FlashbarPublisher.pushItem({
          header:
            "Data Alert: The bypass data may contain employees who left the team during the last 30 days. Please work with the respective employees to resolve these findings.",
          type: "info",
          id: "recent-exits-warning",
        });
      }
    } catch (e) {
      FlashbarPublisher.setItems([
        {
          id: "fetch_bypasses_error",
          type: "error",
          dismissible: true,
          dismissLabel: "Dismiss message",
          onDismiss: FlashbarPublisher.clear,
          content: "There was an error fetching your bypasses.",
        },
      ]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBypasses();
  }, []);

  return (
    <div data-testid={"bypasses"}>
      <SpaceBetween direction="vertical" size="s">
        <BreadcrumbGroup
          test-id="breadcrumb-group"
          items={[
            { text: "Code Defender", href: "/" },
            { text: "All bypasses", href: "/bypasses/all" },
          ]}
          ariaLabel="Breadcrumbs"
          onClick={(ev) => {
            ev.preventDefault();
            navigate(ev.detail.href);
          }}
        />

        <Header
          variant="h1"
          description={
            <>
              Shows bypasses you
              {isBypassesPhase2On() && isAdmin()
                ? " and your team have"
                : "'ve"}{" "}
              made in the last {isBypassesPhase2On() ? "3" : "9"}0 days.
              <br />
              Bypasses are commits made with the <code>--no-verify</code> flag,
              which skips Code Defender's security hooks.{" "}
              <Link
                external
                href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserGuide/#HCapturereasonsforbypassingCD282014no-verify29"
                variant="primary"
              >
                Learn more about bypasses
              </Link>
            </>
          }
          counter={`(${bypasses.length})`}
          actions={
            isAdmin() &&
            isBypassesPhase2On() && (
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  data-testid={"resolve-alerts-button"}
                  variant="primary"
                  onClick={() => setIsDismissBypassModalVisible(true)}
                  disabled={selectedItems.length === 0}
                >
                  Resolve alerts
                </Button>
              </SpaceBetween>
            )
          }
        >
          All bypasses
        </Header>
        <div data-testid={"all-bypasses"}>
          <DismissBypassModal
            isVisible={isDismissBypassModalVisible}
            selectedItems={selectedItems}
            setIsVisible={setIsDismissBypassModalVisible}
            refreshBypasses={() => {
              fetchBypasses();
              setSelectedItems([]);
            }}
          />
          <div data-testid={"bypass-history-table"}>
            <AllBypassesTable
              bypasses={bypasses}
              loading={loading}
              refresh={fetchBypasses}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          </div>
        </div>
      </SpaceBetween>
    </div>
  );
};
