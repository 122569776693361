export const DarkModeImage = ({ lightImageSrc, darkImageSrc, alt, style }) => {
  return (
    <div>
      <img
        className="awsui-util-hide-in-dark-mode"
        src={lightImageSrc}
        alt={alt}
        style={style}
      />
      <img
        className="awsui-util-show-in-dark-mode"
        src={darkImageSrc}
        alt={alt}
        style={style}
      />
    </div>
  );
};
