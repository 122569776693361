import { DataAccessService } from "../core/api";
import { LocalStorage } from "../core/storage";
import { User } from "../core/user";
import { ALERT_COUNTS } from "../core/user/user";

export const getAlertCounts = async (fetchFromCache = false) => {
  try {
    if (fetchFromCache) {
      const alertCounts = LocalStorage.get(ALERT_COUNTS);
      if (alertCounts) {
        return alertCounts;
      }
    }
    const client = new DataAccessService();
    const user = new User();
    const requestParams = {
      username: user.userId,
    };
    const response = await client.get(
      `/api/alerts/counts`,
      undefined,
      requestParams
    );

    const counts = response.complianceAlertsCounts || [];
    LocalStorage.set(ALERT_COUNTS, counts);
    window.dispatchEvent(new Event("ServiceNotificationChange"));
    return counts;
  } catch (err) {
    console.warn(err);
    throw err;
  }
};
