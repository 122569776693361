import {
  BreadcrumbGroup,
  ColumnLayout,
  ExpandableSection,
  Grid,
  Header,
  SpaceBetween,
  Spinner,
  TextContent,
} from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alerts } from "../bypasses-ui/Alerts";
import { FlashbarPublisher } from "../FlashbarPublisher";
import { ComplianceAlertContainer } from "./ComplianceAlertContainer";

import "@amzn/awsui-global-styles/dark-mode-utils.css";
import AddressImageDark from "../../../media/images/compliance/address-dark.svg";
import AddressImageLight from "../../../media/images/compliance/address.svg";
import ResolveImageDark from "../../../media/images/compliance/resolve-dark.svg";
import ResolveImageLight from "../../../media/images/compliance/resolve.svg";
import ReviewImageDark from "../../../media/images/compliance/review-dark.svg";
import ReviewImageLight from "../../../media/images/compliance/review.svg";
import { getAlertCounts } from "../../helpers/getAlertCounts";
import { isAdmin } from "../../helpers/isAdmin";
import { DarkModeImage } from "../dark-mode-image";

export const ComplianceAlerts = () => {
  const [rightAfterBlockedCommitBypasses, setRightAfterBlockedCommitBypasses] =
    useState([]);
  const [misconfiguredToolBypasses, setMisconfiguredToolBypasses] = useState(
    []
  );
  const [missingReasonBypasses, setMissingReasonBypasses] = useState([]);
  const [markedAsIntentionalBypasses, setMarkedAsIntentionalBypasses] =
    useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const guidanceText = {
    review: {
      admin: {
        title: "Review bypasses flagged with alerts",
        description:
          "Click into alerts to understand bypass details and reasons submitted by builders.",
      },
      user: {
        title: "Submit reasons for bypasses flagged with alerts",
        description:
          "After you submit reasons for unacknowledged alerts, your reasons will show up alongside the compliance alerts to help manager understand bypass details.",
      },
    },
    address: {
      admin: {
        title: "Address potential risks with builders",
        description:
          "If needed, reach out to builders with compliance alerts, understand the unique situations, and mitgiate potential risks associated with their bypasses.",
      },
      user: {
        title: "Address potential risks with your manager",
        description:
          "Managers can resolve compliance alerts. If needed, work with your manager to explain your unique situations and mitigate potential risks associated with your bypasses.",
      },
    },
    resolve: {
      admin: {
        title: "Resolve alerts in Code Defender",
        description:
          "After investigation, you can remove the alert label from each bypass by resolving the alert.",
      },
      user: {
        title: "Resolve alerts in Code Defender",
        description:
          "After manager resolves alerts flagged with your bypasses, you will see them removed from your side as well.",
      },
    },
  };

  const fetchBypasses = async () => {
    try {
      setLoading(true);
      const alertCounts = await getAlertCounts();
      setRightAfterBlockedCommitBypasses(
        alertCounts.filter(
          (alert) => alert.alertTag === Alerts.RightAfterBlockedCommit.id
        )[0]
      );
      setMisconfiguredToolBypasses(
        alertCounts.filter(
          (alert) => alert.alertTag === Alerts.ToolMisconfiguration.id
        )[0]
      );
      setMissingReasonBypasses(
        alertCounts.filter(
          (alert) => alert.alertTag === Alerts.MissingReason.id
        )[0]
      );
      setMarkedAsIntentionalBypasses(
        alertCounts.filter(
          (alert) => alert.alertTag === Alerts.MarkedAsIntentional.id
        )[0]
      );
    } catch (e) {
      FlashbarPublisher.setItems([
        {
          id: "fetch_bypasses_error",
          type: "error",
          dismissible: true,
          dismissLabel: "Dismiss message",
          onDismiss: FlashbarPublisher.clear,
          content: "There was an error fetching your bypasses.",
        },
      ]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBypasses();
  }, []);

  return (
    <div data-testid={"bypasses"}>
      <SpaceBetween direction="vertical" size="m">
        <BreadcrumbGroup
          test-id="breadcrumb-group"
          items={[
            { text: "Code Defender", href: "/" },
            { text: "Compliance alerts", href: "/compliance_alerts" },
          ]}
          ariaLabel="Breadcrumbs"
          onClick={(ev) => {
            ev.preventDefault();
            navigate(ev.detail.href);
          }}
        />

        <Header
          variant="h1"
          description={`Shows alerts from you${
            isAdmin() ? " and your team" : ""
          } in the last 30 days.`}
          counter={`(${
            rightAfterBlockedCommitBypasses.alertCount +
              misconfiguredToolBypasses.alertCount +
              missingReasonBypasses.alertCount +
              markedAsIntentionalBypasses.alertCount || 0
          })`}
        >
          Compliance alerts
        </Header>
        <ExpandableSection
          defaultExpanded
          variant="container"
          headerText="Investigate and resolve alerts"
        >
          <ColumnLayout columns={3} variant="text-grid" minColumnWidth={50}>
            <div>
              <DarkModeImage
                lightImageSrc={ReviewImageLight}
                darkImageSrc={ReviewImageDark}
                alt="review bypasses"
                style={{ width: "100%", height: "auto" }}
              />
              <TextContent>
                <h4>
                  {isAdmin()
                    ? guidanceText.review.admin.title
                    : guidanceText.review.user.title}
                </h4>
                {isAdmin()
                  ? guidanceText.review.admin.description
                  : guidanceText.review.user.description}
              </TextContent>
            </div>
            <div>
              <DarkModeImage
                lightImageSrc={AddressImageLight}
                darkImageSrc={AddressImageDark}
                alt="address risks"
                style={{ width: "100%", height: "auto" }}
              />
              <TextContent>
                <h4>
                  {isAdmin()
                    ? guidanceText.address.admin.title
                    : guidanceText.address.user.title}
                </h4>
                {isAdmin()
                  ? guidanceText.address.admin.description
                  : guidanceText.address.user.description}
              </TextContent>
            </div>
            <div>
              <DarkModeImage
                lightImageSrc={ResolveImageLight}
                darkImageSrc={ResolveImageDark}
                alt="resolve alerts"
                style={{ width: "100%", height: "auto" }}
              />
              <TextContent>
                <h4>
                  {isAdmin()
                    ? guidanceText.resolve.admin.title
                    : guidanceText.resolve.user.title}
                </h4>
                {isAdmin()
                  ? guidanceText.resolve.admin.description
                  : guidanceText.resolve.user.description}
              </TextContent>
            </div>
          </ColumnLayout>
        </ExpandableSection>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "30vh",
            }}
          >
            <Spinner size="large" />
          </div>
        )}
        {!loading && (
          <Grid
            gridDefinition={[
              { colspan: 6 },
              { colspan: 6 },
              { colspan: 6 },
              { colspan: 6 },
            ]}
          >
            <ComplianceAlertContainer
              alert={Alerts.RightAfterBlockedCommit}
              count={rightAfterBlockedCommitBypasses.alertCount}
              usersCount={rightAfterBlockedCommitBypasses.alertUserCount}
            />
            <ComplianceAlertContainer
              alert={Alerts.MarkedAsIntentional}
              count={markedAsIntentionalBypasses.alertCount}
              usersCount={markedAsIntentionalBypasses.alertUserCount}
            />
            <ComplianceAlertContainer
              alert={Alerts.ToolMisconfiguration}
              count={misconfiguredToolBypasses.alertCount}
              usersCount={misconfiguredToolBypasses.alertUserCount}
            />
            <ComplianceAlertContainer
              alert={Alerts.MissingReason}
              count={missingReasonBypasses.alertCount}
              usersCount={missingReasonBypasses.alertUserCount}
            />
          </Grid>
        )}
      </SpaceBetween>
    </div>
  );
};
