import { Badge } from "@amzn/awsui-components-react";
import { SeverityLevel } from "../severity-badge/SeverityBadge";

export const Alerts = {
  RightAfterBlockedCommit: {
    id: "RightAfterBlockedCommit",
    title: "Bypass right after a blocked commit",
    tagText: "Right after blocked commit",
    description:
      "After a commit is blocked due to detected secrets, builder chooses to skip Code Defender's scan in order to be unblocked quickly. This is very risky because the code may contain sensitive data that should not be committed into the code base.",
    mitigationLink: "https://example.com",
    severityLevel: SeverityLevel.Critical,
  },
  MarkedAsIntentional: {
    id: "MarkedAsIntentional",
    title: "Intentional bypasses",
    tagText: "Marked as intentional",
    description:
      "Builder marked these bypasses as intentional. This is risky because builders are skipping Code Defender on purpose.",
    mitigationLink: "https://example.com",
    severityLevel: SeverityLevel.High,
  },
  ToolMisconfiguration: {
    id: "RecurringUnintentional",
    title: "Potential tool misconfigurations",
    tagText: "Potential tool misconfiguration",
    description:
      "Builder marked this bypasss as triggers unknown. This alert is triggered when they indicate the same reason for multiple bypasses within 30 days. This is risky because this builder may have issues configuring the tool correctly or encounter a support issue. If they don't fix the configurations, their commits are not protected by Code Defender.",
    mitigationLink: "https://example.com",
    severityLevel: SeverityLevel.High,
  },
  MissingReason: {
    id: "MissingReason",
    title: "Missing reasons for 14+ days",
    tagText: "Missing reason for 14+ days",
    description:
      "This alert is triggered when a bypass is flagged and the builder has not provided a reason for over 2 weeks. This is risky because some of these bypasses could be intentional.",
    mitigationLink: "https://example.com",
    severityLevel: SeverityLevel.Low,
  },
};

// Gets the properly styled badge component for each tag
export function getTagComponent(tag) {
  let text = "Unknown";
  let color = "severity-critical";
  switch (tag) {
    case Alerts.RightAfterBlockedCommit.id:
      text = Alerts.RightAfterBlockedCommit.tagText;
      break;
    case Alerts.MarkedAsIntentional.id:
      text = Alerts.MarkedAsIntentional.tagText;
      break;
    case Alerts.ToolMisconfiguration.id:
      text = Alerts.ToolMisconfiguration.tagText;
      break;
    case Alerts.MissingReason.id:
      text = Alerts.MissingReason.tagText;
      break;
    default:
      return "-";
  }
  return <Badge color={color}>{text}</Badge>;
}

// Formats the list of tags for the select component of the AllBypassesTable
export function getSelectList() {
  return Object.keys(Alerts).map((key) => ({
    value: Alerts[key].id,
    label: getTagComponent(Alerts[key].id),
  }));
}

// Formats the text for the select component of the AllBypassesTable
export function getSelectText(selected) {
  if (selected.length === getSelectList().length) {
    return "All";
  }
  return `${selected.length} selected`;
}
