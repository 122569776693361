// Polaris
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
  Box,
  CopyToClipboard,
  FormField,
  Input,
  Multiselect,
  Pagination,
  Popover,
  Table,
} from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../../styles/components/table-select.scss";
import daysAgo from "../../helpers/daysAgo";
import { getMatchesCountText } from "../../helpers/getMatchesCountText";
import { isBypassesPhase2On } from "../../helpers/isBypassesPhase2On";
import {
  Alerts,
  getSelectList,
  getSelectText,
  getTagComponent,
} from "./Alerts";
import { isAdmin } from "../../helpers/isAdmin";
import { User } from "../../core/user";
import { PhoneToolHyperlink } from "../phone-tool-hyperlink";

export const AllBypassesTable = ({
  bypasses,
  loading,
  selectedItems,
  setSelectedItems,
}) => {
  const [selectedAlertTypes, setSelectedAlertTypes] = useState([]);
  const { hash } = useLocation();

  // List of table ids, also used for defining which attribute on item to sort and filter
  const username = "username";
  const directory = "directory";
  const commitId = "commitId";
  const managerAlias = "managerAlias";
  const time = "time";
  const tags = "tags";
  const reason = "noVerifyReasonType";
  const details = "noVerifyReasonDetails";
  const SEARCHABLE_COLUMNS = [username, directory, commitId, reason, details];

  useEffect(() => {
    //parse hash and verify it is a valid AlertTag
    const alertTag = hash.replace("#", "");
    const alertTagIndex = Object.values(Alerts).findIndex(
      (item) => item.id === alertTag
    );
    const tag = Object.keys(Alerts)[alertTagIndex];
    if (alertTagIndex >= 0) {
      setSelectedAlertTypes([
        {
          value: Alerts[tag].id,
          label: getTagComponent(Alerts[tag].id),
        },
      ]);
    } else {
      setSelectedAlertTypes(getSelectList());
    }
  }, []);

  const getReasonType = (item) => {
    switch (item.noVerifyReasonType) {
      case "Unintentional":
        return "Unknown trigger";
      default:
        if (item.noVerifyReasonType) {
          // Return the reason type if it exists
          return item.noVerifyReasonType;
        }
        return "-";
    }
  };

  // Table collection
  const {
    items,
    actions,
    collectionProps,
    filterProps,
    filteredItemsCount,
    paginationProps,
  } = useCollection(bypasses, {
    pagination: {
      pageSize: 20,
    },
    // Controls which fields the search bar applies to
    // Cloudscape documentation for filtering and sorting: https://cloudscape.aws.dev/get-started/dev-guides/collection-hooks/
    filtering: {
      filteringFunction: (item, filteringText) => {
        if (
          selectedAlertTypes.length !== getSelectList().length &&
          !selectedAlertTypes
            .map((alert) => alert.value)
            .filter((alertType) => item.tags?.includes(alertType)).length
        ) {
          return false;
        }
        if (filteringText === "") {
          return true;
        }
        const filteringTextLowerCase = filteringText.toLowerCase();

        return SEARCHABLE_COLUMNS.map((key) => item[key]).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().indexOf(filteringTextLowerCase) > -1
        );
      },
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: time,
        },
        isDescending: true,
      },
    },
    selection: {},
  });

  return (
    <Table
      {...collectionProps}
      items={items}
      variant="full-page"
      loading={loading}
      loadingText="Loading all bypasses..."
      stickyColumns={{ first: 4, last: 0 }}
      selectionType={isAdmin() && isBypassesPhase2On() ? "multi" : ""}
      selectedItems={selectedItems}
      onSelectionChange={({ detail }) => {
        setSelectedItems(detail.selectedItems);
      }}
      isItemDisabled={(item) =>
        item.username === new User().email || !item.tags
      }
      columnDefinitions={[
        {
          id: directory,
          header: "Directory",
          cell: (item) => {
            // Extract the folder name from the full path
            const match = item.directory.match(/[^/]+(?=\/\.git)/);
            return match ? match[0] : item.directory;
          },
          sortingField: directory,
        },
        {
          id: commitId,
          header: "Commit ID",
          cell: (item) => {
            return item.commitId ? (
              <CopyToClipboard
                copyButtonAriaLabel="Copy Commit ID"
                copyErrorText="Commit ID failed to copy"
                copySuccessText="Commit ID copied"
                textToCopy={item.commitId}
                variant="inline"
              />
            ) : (
              "-"
            );
            },
            sortingField: commitId,
          },
          {
            id: username,
            header: "User",
            cell: (item) => (
            <PhoneToolHyperlink alias={item.username.split("@")[0]} />
            ),
            sortingField: username,
          },
          {
            id: managerAlias,
            header: "Manager",
            cell: (item) => <PhoneToolHyperlink alias={item.managerAlias} />,
            sortingField: managerAlias,
          },
          {
            id: time,
            header: "Commit date",
            cell: (item) => (
            <Popover
              dismissButton={false}
              position="top"
              size="small"
              content={new Date(item.time).toDateString()}
            >
              {daysAgo(item.time)}
            </Popover>
            ),
            sortingField: time,
          },
          {
            id: tags,
            header: "Alert type",
            cell: (item) =>
            item.tags
              ? item.tags.map((tag, index, array) => (
                <span
                key={tag}
                style={{ marginRight: index === array.length - 1 ? 0 : 8 }}
                >
                {getTagComponent(tag)}
                </span>
              ))
              : "-",
            minWidth:
            250 *
            items.reduce(
              (acc, item) => Math.max(acc, item.tags?.length || 0),
              0
            ), // Find the max number of tags for any bypass and set the width to accommodate
          sortingField: tags,
        },
        {
          id: reason,
          header: "Reason",
          cell: (item) => getReasonType(item),
          sortingField: reason,
        },
        {
          id: details,
          header: "Details",
          cell: (item) => item.noVerifyReasonDetails || "-",
          sortingField: details,
        },
      ]}
      filter={
        <div className="input-container">
          <div className="input-filter">
            <Input
              data-testid="input-filter"
              type="search"
              value={filterProps.filteringText}
              onChange={(event) => {
                actions.setFiltering(event.detail.value);
              }}
              ariaLabel="Find bypasses"
              placeholder="Find bypasses"
              clearAriaLabel="clear"
            />
          </div>
          {isBypassesPhase2On() && (
            <div className="select-filter">
              <FormField label="Compliance alerts">
                <Multiselect
                  data-testid="compliance-alerts-filter"
                  hideTokens
                  options={getSelectList()}
                  placeholder={getSelectText(selectedAlertTypes)}
                  selectedOptions={selectedAlertTypes}
                  onChange={(event) => {
                    setSelectedAlertTypes(event.detail.selectedOptions);
                  }}
                  expandToViewport={true}
                />
              </FormField>
            </div>
          )}
          {(filterProps.filteringText ||
            selectedAlertTypes.length !== getSelectList().length) && (
            <span className="filtering-results">
              {getMatchesCountText(filteredItemsCount)}
            </span>
          )}
        </div>
      }
      columnDisplay={[
        { id: directory, visible: true },
        { id: commitId, visible: true },
        { id: username, visible: isAdmin() && isBypassesPhase2On() },
        { id: managerAlias, visible: isAdmin() && isBypassesPhase2On() },
        { id: time, visible: true },
        { id: tags, visible: isBypassesPhase2On() },
        { id: reason, visible: true },
        { id: details, visible: true },
      ]}
      pagination={
        <Box float="right">
          <Pagination {...paginationProps} disabled={loading} />
        </Box>
      }
      empty="No bypasses found"
    />
  );
};
